import moment from "moment";
import consumer_sys_inform_getEnum from '@/lib/data-service/haolv-default/consumer_sys_inform_getEnum';
import consumer_department_getDepartmentList from '@/lib/data-service/haolv-default/consumer_department_getDepartmentList';
import HotelCitySelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv/hotel-city-selector/1.1.0/index.vue";
import consumer_travelRecord_getTravelRecord from '@/lib/data-service/haolv-default/consumer_travelRecord_getTravelRecord';

export default {
    components: {
        HotelCitySelector,
    },
    data () {
        return {
            loading: false,
            departmentList: [], // 部门列表
            options: [],
            params: {
                name: '', // 出差人
                startTime: '',
                endTime: '',
                deptArr: [''], // 部门
                dept: '',
                startAddress: '',
                type: '', // 行程类型

                currentPage: 1,
                pageSize: 10,
            },
            list: [],
            typeList: [],
            citySelector: {},
            dialogVisible: false
        }
    },
    methods: {
        getEum() {
            const __this = this;
            consumer_sys_inform_getEnum(["BusinessType"]).then(res => {
               __this.typeList = res.datas.BusinessType.filter(res => {
                   if (res.code === 1 || res.code === 2 || res.code === 3) {
                       return res
                   }
               });
                __this.typeList.unshift({code: '', msg: '全部'});
            });
        },
        // 获取部门列表
        getDepartmentList() {
            consumer_department_getDepartmentList().then(res => {
                let departmentList = res.datas.departmentListResults;
                departmentList.unshift({deptName: '部门1', deptId: 1});
                departmentList.unshift({deptName: '全部', deptId: ''});
                this.departmentList = departmentList;
            })
        },
        changeDeptId(val) {
            const __this = this;
            function getDeptName(list, activeId) {
                list.forEach(value => {
                    if (value.deptId === activeId) {
                        __this.params.deptName = value.deptName
                    }
                    if (value.childrenList && value.childrenList.length > 0) {
                        getDeptName(value.childrenList, activeId)
                    }
                })
            }
            if (val.length === 0) {
                __this.params.dept = '';
            } else {
                __this.params.dept = val[0];
                getDeptName(__this.options, val[0])
            }
        },
        getTableList() {
            const __this = this;
            __this.loading = true;
            consumer_travelRecord_getTravelRecord(__this.params).then(res => {
                __this.loading = false;
                __this.list = res.datas;
            }).catch(err => {
                __this.loading = false;
            })
        },
        onSearch() {
            const __this = this;
            __this.getTableList();
        }
    },
    created () {},
    async activated () {
        const __this = this;
        //获取当前时间
        __this.params.startTime = moment().format('YYYY-MM');
        __this.getEum();
        __this.getDepartmentList();
        __this.getTableList();
    },
    watch: {
        citySelector: {
            handler(val) {
                const __this = this;
                __this.params.startAddress = val.cityCn
            },
            deep: true,
        },
        "params.dateArr": {
            handler(val) {
                const __this = this;
                __this.params.startTime = val[0];
                __this.params.endTime = val[1];
            },
            deep: true,
        }
    }
}
